/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: CSS Styles for this React Application
*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: white; /*#003314 old navbar, #00A039 banner darker or #00b33e banner lighter*/
  /*min-height: 100vh;*/
  display: block;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Custom CSS Styles */
.container-fluid {
  padding: 25px;
  text-align: center;
}

@media screen and (max-width:650px) { /* Responsive div container for mobile devices with smaller screens */
  .container-fluid {
    padding: 10px;
    text-align: center;
  }
}

.container_img {
  text-align: center;
  color: white;
  background-color: #808080;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px;
  display: block;
}

.footer_text {
  font-size: calc(10px + 1vmin);
  text-align: center;
}

/* Charts */
.container_chart {
  position: relative;
  margin: auto;
  height: 80vh;
  width: 80vw;
}

/* Login form */
.login_form {
  background-color: white;
  color: black;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 25px;
}

@media screen and (max-width:650px) { /* Responsive login form for mobile devices with smaller screens */
  .login_form {
    background-color: white;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding: 25px;
  }
}

/* Responsive Components for mobile devices */
@media screen and (max-width:650px) { /* Responsive table elements for mobile devices with smaller screens */
  td, th {
    display: block;
    clear: both;
  }
}
